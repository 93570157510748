/* GLOBAL STYLES */
body{
  background-color: #17161a;
  font-family: Rubik,sans-serif;
}
.heading1{
 font-size: 45px;
 font-weight:900;
 color: #fff;
}
.heading2{
  font-size: 35px;
  font-weight:900;
  color: #fff;
 }
 .heading3{
  font-size: 45px;
  font-weight:900;
  color: #fff;
 }
.text1{
  font-size: 18px;
  color: silver;
}
.text2{
  font-size: 17px;
  color: silver;
}


/* NAVBAR STYLES */
.logo{
  width: 42px;
}
.navbar-wrap{
  background-color: transparent;
  padding: 8px 50px;
}
.navbar-text{
  color: #fff !important;
  font-size: 13px;
  font-weight: bolder;
  margin-left: 60px;
  margin-top: 10px;
}

.navbar-text:hover{
  color: red !important;
}
.search-logo{
  margin-left: 60px;
  margin-top: 10px;
  margin-right: 40px;
}


/* CONTENT STYLES */
.content-div1{
  padding-top: 70px;
}
.content-sub-div{
padding: 100px 140px 100px 40px;
}
.content-img{
  width: 100%;
  height: 100%;
  object-fit: cover;
}

/* PRIVACY STYLES */
.banner{
  height: 200px;
  margin-left: 10px;
  margin-right: 10px;
  padding-top: 70px;
}

/* FOOTER STYLES */
.footer-wrapper{
  background-color: #130f0e;
}
.footer-div1{
  padding-top: 15px;
  color: white;
  border-bottom: 1px solid grey;
}

.footer-div2{
  padding-top: 55px;
  padding-bottom: 20px;
  color: white;
  border-bottom: 1px solid grey;
}
.ft-social{
  margin-left: 10px;
  margin-right: 10px;
}
.backtotop{
  position: absolute;
  right: 10px;
  left: auto;
  top: 3550px;
  background-color: red;
  width: 50px;
  height: 60px;
  justify-content: center;
  display: flex;
  padding-top: 10px;
  color: wheat;
  font-size: 40px;
}
.backtotop:hover{
  background-color: rgb(212, 12, 12);
  cursor: pointer;
}